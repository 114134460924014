import { Link } from 'react-router-dom'
import logo from '../../image/logo.svg'
import { GlobalContext } from '../../context/GlobalContext'
import { useContext } from 'react'

export const LeftMenu = (props: any) => {
    const auth = useContext(GlobalContext)  
    
    return(
        <div className="left-menu">
            <div className="center">
                <img src={logo} />
            </div>
            <ul className="nav">
                {   
                    auth.user.userRole == "director" || auth.user.userRole == "blogerManager" ? (
                        <li className={`${props.targetPage == "/" ? "active":""}`}>
                            <Link to="/" onClick={()=>{props.setTargetPage("/"); props.setTargetTitle("Список блогеров")}}>
                                <svg className="icon" width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="6" r="4" fill="#1C274C"/>
                                    <path d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z" fill="#1C274C"/>
                                </svg>
                                Блогеры
                                <svg style={{display: "none"}} className="arrow" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="#0F0F0F"/>
                                </svg>
                            </Link>
                        </li>
                    ):""
                }

                {
                    auth.user.userRole == "director" || auth.user.userRole == "sellerManager" ? (
                        <li className={`${props.targetPage == "/sellers/" ? "active":""}`}>
                            <Link to="/sellers/" onClick={()=>{props.setTargetPage("/sellers/"); props.setTargetTitle("Список селлеров")}}>
                                <svg className="icon" version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 32 32" >
                                    <path d="M16,28c0,1.105-0.895,2-2,2s-2-0.895-2-2c0-1.105,0.895-2,2-2S16,26.895,16,28z M25,26 c-1.105,0-2,0.895-2,2c0,1.105,0.895,2,2,2s2-0.895,2-2C27,26.895,26.105,26,25,26z M27,21H12.281l0.5,2H27c0.553,0,1,0.448,1,1 s-0.447,1-1,1H12c-0.459,0-0.858-0.312-0.97-0.757L6.219,5H2C1.447,5,1,4.552,1,4s0.447-1,1-1h5c0.459,0,0.858,0.312,0.97,0.757 L8.78,7h0.001H30c0.308,0,0.599,0.142,0.788,0.385s0.257,0.559,0.182,0.858l-3,12C27.858,20.688,27.459,21,27,21z M22,10 c0,0.552,0.448,1,1,1s1-0.448,1-1c0-0.552-0.448-1-1-1S22,9.448,22,10z M18,10c0,0.552,0.448,1,1,1s1-0.448,1-1c0-0.552-0.448-1-1-1 S18,9.448,18,10z M14,10c0,0.552,0.448,1,1,1s1-0.448,1-1c0-0.552-0.448-1-1-1S14,9.448,14,10z M12,10c0-0.552-0.448-1-1-1 s-1,0.448-1,1c0,0.552,0.448,1,1,1S12,10.552,12,10z M14,14c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.552,0.448,1,1,1S14,14.552,14,14z M16,18c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.552,0.448,1,1,1S16,18.552,16,18z M18,14c0-0.552-0.448-1-1-1s-1,0.448-1,1 c0,0.552,0.448,1,1,1S18,14.552,18,14z M20,18c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.552,0.448,1,1,1S20,18.552,20,18z M22,14 c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.552,0.448,1,1,1S22,14.552,22,14z M24,18c0-0.552-0.448-1-1-1s-1,0.448-1,1 c0,0.552,0.448,1,1,1S24,18.552,24,18z M26,14c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.552,0.448,1,1,1S26,14.552,26,14z M27,11 c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1s-1,0.448-1,1C26,10.552,26.448,11,27,11z"/>
                                </svg>
                                Селлеры
                            </Link>
                        </li>   
                    ):""
                }
                <li className={`${props.targetPage == "/integration/" ? "active":""}`}>
                    <Link to="/integration/" onClick={()=>{props.setTargetPage("/integration/"); props.setTargetTitle("Список интеграций")}}>
                        <svg className='icon' fill="#000000" xmlns="http://www.w3.org/2000/svg"  width="22px" height="22px" viewBox="0 0 100 100">
                            <g>
                                <g>
                                    <path d="M38,29h4c0.6,0,1-0.4,1-1v-3h14v3c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1v-3c0-3.3-2.7-6-6-6H43c-3.3,0-6,2.7-6,6 v3C37,28.6,37.4,29,38,29z"/>
                                </g>
                                <g>
                                    <path d="M74,35H26c-3.3,0-6,2.7-6,6v32c0,3.3,2.7,6,6,6h48c3.3,0,6-2.7,6-6V41C80,37.7,77.3,35,74,35z"/>
                                </g>
                            </g>
                        </svg>
                        Проекты
                    </Link>
                </li> 

                {
                    auth.user.userRole == "director" ? (
                        <li className={`${props.targetPage == "/report/" ? "active":""}`}>
                            <Link to="/report/" onClick={()=>{props.setTargetPage("/report/"); props.setTargetTitle("Отчеты")}}>
                                <svg className="icon" version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 32 32" >
                                    <path className="afiado_een" d="M6,11h4v17H6V11z M22,16v12h4V16H22z M14,28h4V4h-4V28z"/>
                                </svg>
                                Отчеты
                            </Link>
                        </li>
                    ):""
                } 
                <li className={`${props.targetPage == "/complaint/" ? "active":""}`}>
                    <Link to="/complaint/" onClick={()=>{props.setTargetPage("/complaint/"); props.setTargetTitle("Жалобы")}}>
                        <svg className="icon" width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-1.5-5.009c0-.867.659-1.491 1.491-1.491.85 0 1.509.624 1.509 1.491 0 .867-.659 1.509-1.509 1.509-.832 0-1.491-.642-1.491-1.509zM11.172 6a.5.5 0 0 0-.499.522l.306 7a.5.5 0 0 0 .5.478h1.043a.5.5 0 0 0 .5-.478l.305-7a.5.5 0 0 0-.5-.522h-1.655z" fill="#000000"/>
                        </svg>
                        Жалобы
                    </Link>
                </li> 
                <li className={`${props.targetPage == "/payments/" ? "active":""}`}>
                    <Link to="/payments/" onClick={()=>{props.setTargetPage("/payments/"); props.setTargetTitle("Платежи")}}>
                        <svg className='icon' width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8C2 5.79086 3.79086 4 6 4H18C20.2091 4 22 5.79086 22 8V8.5C22 8.77614 21.7761 9 21.5 9L2.5 9C2.22386 9 2 8.77614 2 8.5V8ZM2.5 11C2.22386 11 2 11.2239 2 11.5V16C2 18.2091 3.79086 20 6 20H18C20.2091 20 22 18.2091 22 16V11.5C22 11.2239 21.7761 11 21.5 11L2.5 11ZM13 15C13 14.4477 13.4477 14 14 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H14C13.4477 16 13 15.5523 13 15Z" fill="#000000"/>
                        </svg>
                        Платежи
                    </Link>
                </li> 

                
            </ul>
        </div>
    )
}