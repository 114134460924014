export const Pagination = (props: any) => {
    return(
        <div className="pagination block-wrapper">
            {
                props.page > 1 ? (
                    <div>
                        <span>
                            {"<"}
                        </span>
                    </div>
                ):""
            }
            {
                props.page > 3 ? (
                    <>
                        <div onClick={()=>{
                            props.loadData(1)
                        }}>
                            <span>1</span>
                        </div>
                        <div>...</div>
                    </>
                ):""
            }
            {
                props.page-2 > 0 ? (
                    <div 
                        onClick={()=>{
                            props.loadData(props.page-2)
                        }}
                    >
                        <span>{props.page-2}</span>
                    </div>
                ):""
            }
            {
                props.page-1 > 0 ? (
                    <div 
                        onClick={()=>{
                            props.loadData(props.page-1)
                        }}
                    >
                        <span>{props.page-1}</span>
                    </div>
                ):""
            }
            
            
            <div className="nactive">
                {props.page}
            </div>


            {
                props.page+1 <= props.pageCount ? (
                    <div 
                        onClick={()=>{
                            props.loadData(props.page+1)
                        }}
                    >
                        <span>{props.page+1}</span>
                    </div>
                ):""
            }
            {
                props.page+2 <= props.pageCount ? (
                    <div 
                        onClick={()=>{
                            props.loadData(props.page+2)
                        }}
                    >
                        <span>{props.page+2}</span>
                    </div>
                ):""
            } 


{
                props.page+2 < props.pageCount ? (
                    <>
                        <div>...</div>
                        <div onClick={()=>{
                            props.loadData(props.pageCount)
                        }}>
                            <span>{props.pageCount}</span>
                        </div>
                    </>
                ):""
            }

            {
                props.page < props.pageCount ? (
                    <div>
                        <span>
                            {">"}
                        </span>
                    </div>
                ):""
            }
        </div>
    )
}