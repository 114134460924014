import axios from 'axios';
import {host} from "../classes";


export const axiosBase = axios.create({
    baseURL: host,
});
axiosBase.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('userStorageBlogerAdmin') as any).userToken;
    config.headers.Authorization = token && `Token ${token}`;
    config.headers["Content-Type"] = 'application/json';

    return config;
});
