import { ChangeEvent, useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"

export const LoginPage = () => {
    const auth = useContext(GlobalContext)  

    const [form, setForm] = useState({ 
        email: "",
        pass: ""
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()

        auth.user.Login(form.email, form.pass).then(function(response){
            if(response.error == true){
                alert(response.msg)
            }else{
                window.location.reload()
            }
        }).catch(function(error){
            alert("error")
        })
    }
    
    return(
        <>
            <div className="login">
                <div className="form-wrapper">
                    <h1>Авторизация</h1>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <input type="text" required placeholder="Email" name="email" value={form.email} onChange={changeHandler} />
                        </label>
                        <label>
                            <input type="password" required placeholder="Пароль" name="pass" value={form.pass} onChange={changeHandler} />
                        </label>
                        <button>Войти</button>
                    </form>
                </div>
            </div>
        </>
    )
}