export const Loader = () => {
    return(
        <h2
            style={{
                textAlign: "center",
                marginTop: 40,
            }}
        >
            Загрузка...
        </h2>
    )
}