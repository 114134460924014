import { Route, Routes } from 'react-router-dom';
import { Layout } from './layouts/layout';
import { useMyRoutes } from './routes';
import { LeftMenu } from './component/LeftMenu';
import { useContext, useState } from 'react';
import { Header } from './component/Header';
import { GlobalContext } from './context/GlobalContext';
import { User } from './classes';

function App() {
    const auth = useContext(GlobalContext)
    
    const routes = useMyRoutes() 

    const [openMenu, setOpenMenu] = useState(false)
    const [targetPage, setTargetPage] = useState(window.location.pathname)
    const [targetTitle, setTargetTitle] = useState("Список блогеров")
    return (
        <>
            <GlobalContext.Provider value={{
                user: new User
            }}> 
                {
                    auth.user.userToken != "" ? (
                        <>  
                            <LeftMenu 
                                openMenu={openMenu} 
                                setOpenMenu={setOpenMenu} 
                                targetPage={targetPage}
                                setTargetPage={setTargetPage}
                                setTargetTitle={setTargetTitle}
                            />
                            <div className="main-space">
                                <Header targetTitle={targetTitle} />
                                <Routes>
                                    <Route element={<Layout />}>{routes}</Route>
                                </Routes> 
                            </div>
                        </>
                    ):(
                        <Routes>
                            <Route element={<Layout />}>{routes}</Route>
                        </Routes> 
                    )
                }
            </GlobalContext.Provider>
        </>
    );
}

export default App;
