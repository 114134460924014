import {axiosBase} from "../constants/axiosBase";

type ChangeOwnInfoType = {
    isOwn: boolean;
    sellerId: number;
}

export const changeOwnInfo = async (data:ChangeOwnInfoType) => {
    const response = await axiosBase.post('/seller/is-own/', data)
}
