import axios from 'axios'
import { getSearchParamsForLocation } from 'react-router-dom/dist/dom'
export const link = "https://api.blogerito.ru/dashboard/" //для локальнгого
export const host = "https://api.blogerito.ru/"
// export const link = "http://10.55.1.60:8000/dashboard/" //для локальнгогоn
// export const host = "http://10.55.1.60:8000/"

export class User{
    userToken: string = ""
    userRole: string = ""
    userName: string = ""

    constructor(){
        if(localStorage.getItem("userStorageBlogerAdmin")){
            const userStorage = JSON.parse(localStorage.getItem("userStorageBlogerAdmin") || "")
            console.log(userStorage)
            this.userToken = userStorage.userToken
            this.userRole = userStorage.userRole
            this.userName = userStorage.userName
        }
    }

    Logout(){
        localStorage.removeItem("userStorageBlogerAdmin")
        this.userToken = ""
        this.userRole = ""
        this.userName = ""
    }


    async Login(email: string, password: string){
        let data = {
            msg: "",
            error: false
        }
        const hThis = this
        await axios.post(`${link}login/`,{
            email: email,
            password: password
        }, {
            headers: {}
        }).then((res) => {
            console.log(res)
            if(res.data.error == false){
                localStorage.setItem("userStorageBlogerAdmin", JSON.stringify({
                    userToken: res.data.data.token,
                    userRole: res.data.data.group,
                    userName: email
                }))

                hThis.userToken = res.data.data.token
                hThis.userRole = res.data.data.group
                hThis.userName = email
            }else{
                data = {
                    msg: "Логин или пароль указан неверно",
                    error: true
                }
            }
        }).catch((error)=>{
            console.log(error)
        })
        return data
    }
}

//-----------------------------------------------------//
export class DailyReportItem{
    pk: number
    date: string

    constructor(item: {pk: number, date: string}){
        this.pk = item.pk
        this.date = item.date
    }
}
export class DailyReport{
    list: DailyReportItem[] = []

    async LoadExcel(startDate: string, endDate: string, token: string){
        let array: any[] = []
        await axios.get(`${link}dailyreport-list/0/`, {
            params: {
                startDate: startDate,
                endDate: endDate
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            array = res.data
        }).catch(error => {

        })
        return array
    }

    async LoadData(token: string){
        const Hthis = this

        await axios.get(`${link}dailyreport-list/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.list = []
            res.data.forEach((item: DailyReportItem, index: number) => {
                Hthis.list.push(new DailyReportItem({...item}))
            })
        }).catch(error => {

        })
    }
}

//-----------------------------------------------------//
export class PayItem{
    pk: number
    title: string
    user: {
        pk: number
        email: string
    }
    status: string
    date: string

    constructor(item={pk: 0, title: "", user: {pk: 0, email: ""}, status: "", date: ""}){
        this.pk = item.pk
        this.title = item.title
        this.user = item.user
        this.status = item.status
        this.date = item.date
    }

}
export class Pay{
    list: PayItem[] = []
    countPage: number = 1
    targetPage: number = 1

    constructor(){

    }

    async LoadData(token: string, search: string, page: number = 0){
        if(page != 0)
            this.targetPage = page

        const Hthis = this

        await axios.get(`${link}pay/`, {
            params: page > 0 ? {
                "search": search,
                "page": Hthis.targetPage
            }:{
                "search": search,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.list = []
            res.data.content.forEach((item: PayItem, index: number) => {
                Hthis.list.push(new PayItem({...item}))
            })
            Hthis.countPage = Math.ceil(res.data.pageCount / 50)
        }).catch(error=>{
            console.log(error)
        })
        return true
    }
}

//-----------------------------------------------------//
export class ComplaintsItem {
    pk: number
    bloger: {
        pk: number
        user: {
            pk: number
            email: string
        }
    } | null
    reportsType: string
    integration: {
        title: string
        pk: number
        seller: {
            pk: number
            user: {
                pk: number
                email: string
            }
        }
    } | null
    date: string
    minidesc: string

    constructor(item:{
        pk: number
        bloger: {
            pk: number
            user: {
                pk: number
                email: string
            }
        } | null
        reportsType: string
        integration: {
            title: string
            pk: number
            seller: {
                pk: number
                user: {
                    pk: number
                    email: string
                }
            }
        } | null
        date: string
        minidesc: string
    }){
        this.pk = item.pk
        this.bloger = item.bloger
        this.integration = item.integration
        this.reportsType = item.reportsType
        this.date = item.date
        this.minidesc = item.minidesc
    }
}
export class Complaints {
    list: ComplaintsItem[] = []
    countPage: number = 1
    targetPage: number = 1

    constructor(){

    }

    async LoadData(token: string, search: string, page: number = 0){
        if(page != 0)
            this.targetPage = page

        const Hthis = this

        await axios.get(`${link}complaints/`, {
            params: page > 0 ? {
                "search": search,
                "page": Hthis.targetPage
            }:{
                "search": search,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.list = []
            res.data.content.forEach((item: ComplaintsItem, index: number) => {
                Hthis.list.push(new ComplaintsItem({...item}))
            })
            Hthis.countPage = Math.ceil(res.data.pageCount / 50)
        }).catch(error=>{
            console.log(error)
        })
        return true
    }
}

//-----------------------------------------------------//
export class BlogerApplications{
    id: number
    status: string
    date: string
    statusDate: string
    integrationType: string
    cancellationReason: string
    bloger: {
        pk: number,
        user: {
            pk: number
            email: string
        }
    }
    integration: {
        pk: number
        title: string
        maxIntegrations: string
        seller: {
            user: {
                pk: number
                email: string
            }
        }
        date: string
    }

    constructor(item: {
        id: number,
        status: string,
        date: string,
        statusDate: string,
        integrationType: string,
        cancellationReason: string,
        bloger: {
            pk: number,
            user: {
                pk: number
                email: string
            }
        }
        integration: {
            pk: number
            title: string
            maxIntegrations: string
            seller: {
                user: {
                    pk: number
                    email: string
                }
            }
            date: string
        }
    }){
        this.id = item.id
        this.status = item.status
        this.date = item.date
        this.cancellationReason = item.cancellationReason
        this.statusDate = item.statusDate
        this.integrationType = item.integrationType
        this.integration = item.integration
        this.bloger = item.bloger
    }
}
export class BlogerItem {
    list: BlogerApplications[] = []
    countPage: number = 1
    targetPage: number = 1

    pk: number
    user: {
        pk: number,
        email: string
    }
    blogerBlock: boolean = false
    name: string
    phone: string
    isFrozen: boolean
    youtube: null | string
    vk: null | string
    instagram: null | string
    rutube: null | string
    telegram: null | string
    subscribers: string
    date: string
    tglogin: string
    docConf: boolean = false
    infoConf: boolean = false
    image: string = ""
    regLink: string = ""
    integration: number = 0

    goodreview: number = 0
    badreview: number = 0

    applications_proccess: number = 0
    applications_work: number = 0

    dateActivity: string = ""

    in_process: number = 0
    tz_conf: number = 0
    w8paydata: number = 0
    payment_w8: number = 0
    payment_completed: number = 0
    product_ordered: number = 0
    product_received: number = 0
    post_posted: number = 0
    bloger_done: number = 0
    w8_bloger_feedback: number = 0

    applications_done: number = 0
    applications_cancelled: number = 0

    LegalFIO: string = ""
    LegalINN: string = ""
    LagalConsultation: boolean = false

    blocks?: [
        {
            id: number,
            active: boolean,
            andDate: string,
            type: string,
            date: string,
            reason: string,
        }
    ]

    constructor(item: {
        pk: number,
        user: {
            pk: number,
            email: string
        },
        tglogin: string,
        name: string,
        phone: string,
        isFrozen: boolean,
        youtube: null | string,
        vk: null | string,
        instagram: null | string,
        rutube: null | string,
        telegram: null | string,
        subscribers: string,
        date: string
    } = {
        pk: 0,
        user: {
            pk: 0,
            email: ""
        },
        tglogin: "",
        name: "",
        phone: "",
        youtube: "",
        vk: "",
        instagram: "",
        rutube: "",
        telegram: "",
        subscribers: "",
        date: "",
        isFrozen:false
    }){
        this.pk = item.pk
        this.user = item.user
        this.name = item.name
        this.phone = item.phone
        this.youtube = item.youtube
        this.vk = item.vk
        this.instagram = item.instagram
        this.rutube = item.rutube
        this.telegram = item.telegram
        this.subscribers = item.subscribers
        this.date = item.date
        this.tglogin = item.tglogin
        this.isFrozen = item.isFrozen
    }

    async LoadStat(token: string){
        let data = {
            "error": false,
            "msg": {
                active: false,
                tglogin: "",
                name: "",
                phone: "",
                image: "",
                bDate: "",
                city: "",
                minidesc: "",
                category: "",
                regLink: "",
                youtube: "",
                vk: "",
                instagram: "",
                rutube: "",
                telegram: "",
                desc: "",
                doc: "",
                subscribers: "",
                men: "",
                woman: "",
                docConf: false,
                infoConf: false,
                phoneConf: false,
                CatalogConf: false,
                integrationCost: ""
            }
        }
        const Hthis = this

        await axios.get(`${link}bloger-update/`,{
            params: {
                'pk': Hthis.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = {
                "error": false,
                "msg": {
                    active: res.data.msg.active,
                    tglogin: res.data.msg.tglogin,
                    name: res.data.msg.name,
                    phone: res.data.msg.phone,
                    image: "", //res.data.msg.image,
                    bDate: res.data.msg.bDate != null ? res.data.msg.bDate : "",
                    city: res.data.msg.city != null ? res.data.msg.city : "",
                    minidesc: res.data.msg.minidesc != null ? res.data.msg.minidesc : "",
                    category: res.data.msg.category != null ? res.data.msg.category : "",
                    regLink: res.data.msg.regLink != null ? res.data.msg.regLink : "",
                    youtube: res.data.msg.youtube != null ? res.data.msg.youtube : "",
                    vk: res.data.msg.vk != null ? res.data.msg.vk : "",
                    instagram: res.data.msg.instagram != null ? res.data.msg.instagram : "",
                    rutube: res.data.msg.rutube != null ? res.data.msg.rutube : "",
                    telegram: res.data.msg.telegram != null ? res.data.msg.telegram : "",
                    desc: res.data.msg.desc != null ? res.data.msg.desc : "",
                    doc: "", //res.data.msg.doc,
                    subscribers: res.data.msg.subscribers != null ? res.data.msg.subscribers : "",
                    men: res.data.msg.men != null ? res.data.msg.men : "",
                    woman: res.data.msg.woman != null ? res.data.msg.woman : "",
                    docConf: res.data.msg.docConf,
                    infoConf: res.data.msg.infoConf,
                    phoneConf: res.data.msg.phoneConf,
                    CatalogConf: res.data.msg.CatalogConf,
                    integrationCost: res.data.msg.integrationCost,
                }
            }
        }).catch(error => {
            console.log(error)
        })

        return data
    }

    async LoadData(token: string){
        const Hthis = this
        await axios.get(`${link}bloger/${this.pk}/`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.LegalFIO = res.data.content.LegalFIO
            Hthis.LegalINN = res.data.content.LegalINN
            Hthis.LagalConsultation = res.data.content.LagalConsultation
            Hthis.tglogin = res.data.content.tglogin

            Hthis.user = res.data.content.user
            Hthis.name = res.data.content.name
            Hthis.phone = res.data.content.phone
            Hthis.docConf = res.data.content.docConf
            Hthis.infoConf = res.data.content.infoConf
            Hthis.image = `https://api.blogerito.ru/${res.data.content.image}`
            Hthis.regLink = res.data.content.regLink
            Hthis.subscribers = res.data.content.subscribers
            Hthis.date = res.data.content.date
            Hthis.integration = res.data.content.integration
            Hthis.goodreview = res.data.content.goodreview
            Hthis.badreview = res.data.content.badreview
            Hthis.dateActivity = res.data.content.dateActivity
            Hthis.blogerBlock = res.data.content.blogerBlock

            Hthis.applications_proccess = res.data.applications_proccess
            Hthis.applications_work = res.data.applications_work

            Hthis.in_process = res.data.in_process
            Hthis.tz_conf = res.data.tz_conf
            Hthis.w8paydata = res.data.w8paydata
            Hthis.payment_w8 = res.data.payment_w8
            Hthis.payment_completed = res.data.payment_completed
            Hthis.product_ordered = res.data.product_ordered
            Hthis.product_received = res.data.product_received
            Hthis.post_posted = res.data.post_posted
            Hthis.bloger_done = res.data.bloger_done
            Hthis.w8_bloger_feedback = res.data.w8_bloger_feedback

            Hthis.applications_done = res.data.applications_done
            Hthis.applications_cancelled = res.data.applications_cancelled
            Hthis.isFrozen = res.data.content.isFrozen
            Hthis.blocks = res.data.blocks


        }).catch(error => {
            console.log(error)
        })
    }

    async Blocked(token: string, block: boolean, day: string, reason: string){
        let msg = ""
        await axios.get(`${link}userblock/`,{
            params: {
                "pk": this.pk,
                "day": day,
                "reason": reason
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            msg = res.data.msg
        }).catch(error => {
            console.log(error)
        })
        return msg
    }

    async DelItem(token: string){
        await axios.post(`${link}bloger-del/`, {
            pk: [this.pk]
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            console.log(response.data)
            console.log("true")
        })
    }

    async LoadApplications(token: string, page: number, excel = false){
        let array: any[] = []
        this.targetPage = page
        const Hthis = this
        await axios.get(`${link}applications/`, {
            params: {
                "page": Hthis.targetPage,
                "bloger": Hthis.pk,
                "excel": excel ? "1" : "0"
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            if(excel){

                response.data.content.forEach((item: BlogerApplications, index: number) => {
                    array.push({
                        id: item.id,
                        date: item.date,
                        statusDate: item.statusDate,
                        status: item.status,
                        integration_title: item.integration.title,
                        integration_id: item.integration.pk
                    })
                })

            }else{
                Hthis.countPage = Math.ceil(response.data.pageCount / 25)
                Hthis.list = []
                response.data.content.forEach((item: BlogerApplications, index: number) => {
                    Hthis.list.push(new BlogerApplications({...item}))
                })
            }
        })
        return array
    }

}
export class Blogers {
    list: BlogerItem[] = []
    countPage: number = 1
    targetPage: number = 1

    constructor(){}

    async LoadData(token: string, search: string, page: number = 0, legal: boolean = false){
        if(page != 0)
            this.targetPage = page

        const Hthis = this

        await axios.get(`${link}bloger/`, {
            params: page > 0 ? {
                "search": search,
                "legal": legal ? "T" : "F",
                "page": Hthis.targetPage
            }:{
                "search": search,
                "legal": legal ? "T" : "F",
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.list = []
            res.data.content.forEach((item: BlogerItem, index: number) => {
                Hthis.list.push(new BlogerItem({...item}))
            })
            Hthis.countPage = Math.ceil(res.data.pageCount / 100)
        }).catch(error=>{
            console.log(error)
        })
        return true
    }

    async DelItem(array: number[], token: string){
        await axios.post(`${link}bloger-del/`, {
            pk: array
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            console.log(response.data)
            console.log("true")
        })
    }

    async LoadExcel(token: string, search: string, selected: string, legal: boolean = false){
        let array: any[] = []
        await axios.get(`${link}bloger-excel/`, {
            params: {
                search: search,
                selected: selected,
                legal: legal ? "T" : "F",
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            array = res.data.msg
        }).catch(error => {
            console.log(error)
        })

        return array
    }

}

//-----------------------------------------------------//
export class IntegrationsItem {
    countPage: number = 1
    targetPage: number = 1
    list: BlogerApplications[] = []

    pk: number
    title: string
    maxIntegrations: number
    seller: {
        pk?: number
        user: {
            pk: number
            email: string
        }
    }
    date: string
    image: string = ""
    format1: boolean = false
    format2: boolean = false
    format3: boolean = false
    format4: boolean = false
    format5: boolean = false
    miniDesc: string = ""
    desc: string = ""
    applicationCount: number = 0
    productTitle: string = ""
    tz: string = ""

    constructor(item:{
        pk: number
        title: string
        maxIntegrations: number
        seller: {
            pk?: number
            user: {
                pk: number
                email: string
            }
        }
        date: string
    } = {
        pk: 0,
        title: "",
        maxIntegrations: 0,
        seller: {
            pk: 0,
            user: {
                pk: 0,
                email: ""
            }
        },
        date: ""
    }){
        this.pk = item.pk
        this.title = item.title
        this.maxIntegrations = item.maxIntegrations
        this.seller = item.seller
        this.date = item.date
    }

    async LoadData(token: string){
        const Hthis = this
        await axios.get(`${link}integrations/${this.pk}/`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.title = res.data.content.title
            Hthis.image = `https://api.blogerito.ru/${res.data.content.productImage}`

            Hthis.format1 = res.data.content.format1
            Hthis.format2 = res.data.content.format2
            Hthis.format3 = res.data.content.format3
            Hthis.format4 = res.data.content.format4
            Hthis.format5 = res.data.content.format5

            Hthis.miniDesc = res.data.content.miniDesc
            Hthis.desc = res.data.content.desc

            Hthis.seller = res.data.content.seller

            Hthis.maxIntegrations = res.data.content.maxIntegrations
            Hthis.applicationCount = res.data.content.applicationCount

            Hthis.productTitle = res.data.content.productTitle
            Hthis.tz = res.data.content.tz
        }).catch(error => {})

    }

    async LoadStat(token: string){
        let data = {
            "error": false,
            "msg": {
                title: "",
                productTitle: "",
                miniDesc: "",
                desc: "",
                productLink: "",
                productImage: "",
                maxIntegrations: "",
                tz: "",
                price: 0,
                soc: "",
                format1: false,
                format2: false,
                format3: false,
                format4: false,
                format5: false,
            }
        }
        const Hthis = this

        await axios.get(`${link}integrations-update/`,{
            params: {
                'pk': Hthis.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = {
                "error": false,
                "msg": {
                    title: res.data.msg.title,
                    productTitle: res.data.msg.productTitle,
                    miniDesc: res.data.msg.miniDesc,
                    desc: res.data.msg.desc,
                    productLink: res.data.msg.productLink,
                    productImage: res.data.msg.productImage,
                    tz: res.data.msg.tz,
                    price: res.data.msg.price,
                    soc: "",
                    maxIntegrations: res.data.msg.maxIntegrations,
                    format1: res.data.msg.format1,
                    format2: res.data.msg.format2,
                    format3: res.data.msg.format3,
                    format4: res.data.msg.format4,
                    format5: res.data.msg.format5
                }
            }
        }).catch(error => {
            console.log(error)
        })

        return data
    }

    async DelItem(array: number[], token: string){
        await axios.post(`${link}integrations-del/`, {
            pk: [this.pk]
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            console.log(response.data)
            console.log("true")
        })
    }

    async LoadApplications(token: string, page: number, excel = false){
        let array: any[] = []
        this.targetPage = page
        const Hthis = this
        await axios.get(`${link}applications/`, {
            params: {
                "page": Hthis.targetPage,
                "integration": Hthis.pk,
                "excel": excel ? "1" : "0"
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            if(excel){

                response.data.content.forEach((item: BlogerApplications, index: number) => {
                    array.push({
                        id: item.id,
                        date: item.date,
                        statusDate: item.statusDate,
                        status: item.status,
                        cancellationReason: item.cancellationReason,
                        integration_title: item.integration.title,
                        integration_id: item.integration.pk
                    })
                })

            }else{
                Hthis.countPage = Math.ceil(response.data.pageCount / 25)
                Hthis.list = []
                response.data.content.forEach((item: BlogerApplications, index: number) => {
                    Hthis.list.push(new BlogerApplications({...item}))
                })
            }
        })
        return array
    }
}
export class Integrations {
    list: IntegrationsItem[] = []
    countPage: number = 1
    targetPage: number = 1

    constructor(){}

    async LoadData(
        token: string,
        search: string,
        page: number = 0,
        startDate: string,
        endDate: string,
        maxCount_start: number,
        maxCount_end: number,

        processCount_start: number,
        processCount_end: number,

        doneCount_start: number,
        doneCount_end: number,

        status: string,
        fIntegration: boolean,

        applicationCount_start: number,
        applicationCount_end: number,
        seller: number = 0
    ){
        if(page != 0)
            this.targetPage = page

        const Hthis = this

        await axios.get(`${link}integrations/`, {
            params: {
                "search": search,
                "page": page > 0 ? Hthis.targetPage:"",
                "startDate": startDate,
                "endDate": endDate,

                "maxCount_start": maxCount_start != 0 ? maxCount_start : null,
                "maxCount_end": maxCount_end != 0 ? maxCount_end : null,

                "processCount_start": processCount_start != 0 ? processCount_start : null,
                "processCount_end": processCount_end != 0 ? processCount_end : null,

                "doneCount_start": doneCount_start != 0 ? doneCount_start : null,
                "doneCount_end": doneCount_end != 0 ? doneCount_end : null,

                "status": status,
                "fIntegration": fIntegration,

                "applicationCount_start": applicationCount_start != 0 ? applicationCount_start : null,
                "applicationCount_end": applicationCount_end != 0 ? applicationCount_end : null,
                "seller": seller != 0 ? seller : null
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.list = []
            res.data.content.forEach((item: IntegrationsItem, index: number) => {
                Hthis.list.push(new IntegrationsItem({...item}))
            })
            Hthis.countPage = Math.ceil(res.data.pageCount / 50)
        }).catch(error=>{
            console.log(error)
        })
        return true
    }

    async DelItem(array: number[], token: string){
        await axios.post(`${link}integrations-del/`, {
            pk: array
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            console.log(response.data)
            console.log("true")
        })
    }

    async LoadExcel(
        token: string,
        search: string,
        startDate: string,
        endDate: string,
        maxCount_start: number,
        maxCount_end: number,
        processCount_start: number,
        processCount_end: number,
        doneCount_start: number,
        doneCount_end: number,
        status: string,
        fIntegration: boolean,
        applicationCount_start: number,
        applicationCount_end: number,
        selected: string,
        seller: number = 0
    ){
        let array: any[] = []
        await axios.get(`${link}integrations-excel/`, {
            params: {
                "search": search,
                "startDate": startDate,
                "endDate": endDate,

                "maxCount_start": maxCount_start != 0 ? maxCount_start : null,
                "maxCount_end": maxCount_end != 0 ? maxCount_end : null,

                "processCount_start": processCount_start != 0 ? processCount_start : null,
                "processCount_end": processCount_end != 0 ? processCount_end : null,

                "doneCount_start": doneCount_start != 0 ? doneCount_start : null,
                "doneCount_end": doneCount_end != 0 ? doneCount_end : null,

                "status": status,
                "fIntegration": fIntegration,

                "applicationCount_start": applicationCount_start != 0 ? applicationCount_start : null,
                "applicationCount_end": applicationCount_end != 0 ? applicationCount_end : null,
                "selected": selected,
                "seller": seller != 0 ? seller : null

            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            array = res.data.msg
        }).catch(error => {
            console.log(error)
        })
        return array
    }
}

//-----------------------------------------------------//
export class SellerItem {
    pay: {
        pk: number
        title: string
        status: string
        date: string
    }[] = []
    pk: number
    user: {
        pk: number
        email: string
    }
    name: string
    phone: string
    tglogin: string
    subscribeDate: string

    image: string = ""
    goodreview: number = 0
    badreview: number = 0
    integrationsCount: number = 0
    bDate: string = ""

    docConf: boolean = false
    infoConf: boolean = false
    phoneConf: boolean = false

    integrations: number = 0
    applications_proccess: number = 0
    applications_work: number = 0
    applications_cancelled: number = 0

    in_process: number = 0
    tz_conf: number = 0
    w8paydata: number = 0
    payment_w8: number = 0
    payment_completed: number = 0
    product_ordered: number = 0
    product_received: number = 0
    post_posted: number = 0
    bloger_done: number = 0
    w8_bloger_feedback: number = 0
    applications_done: number = 0
    isOwn: boolean = false

    constructor(item: {
        pk: number
        user: {
            pk: number
            email: string
        }
        name: string
        phone: string
        tglogin: string
        subscribeDate: string
        isOwn: boolean
    }={
        pk: 0,
        user: {
            pk: 0,
            email: ""
        },
        name: "",
        phone: "",
        tglogin: "",
        subscribeDate: "",
        isOwn: false
    }){
        this.pk = item.pk
        this.user = item.user
        this.name = item.name
        this.phone = item.phone
        this.tglogin = item.tglogin
        this.subscribeDate = item.subscribeDate
        this.isOwn = item.isOwn
    }

    async LoadData(token: string){
        const Hthis = this
        await axios.get(`${link}seller/${this.pk}/`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            Hthis.user = res.data.content.user
            Hthis.name = res.data.content.name
            Hthis.tglogin = res.data.content.tglogin
            Hthis.phone = res.data.content.phone
            Hthis.image = `https://api.blogerito.ru/${res.data.content.image}`
            Hthis.isOwn = res.data.content.isOwn

            Hthis.goodreview = res.data.content.goodreview
            Hthis.badreview = res.data.content.badreview
            Hthis.integrationsCount = res.data.content.integrationsCount
            Hthis.subscribeDate = res.data.content.subscribeDate
            Hthis.bDate = res.data.content.bDate

            Hthis.docConf = res.data.content.docConf
            Hthis.infoConf = res.data.content.infoConf
            Hthis.phoneConf = res.data.content.phoneConf

            Hthis.pay = res.data.pay

            Hthis.integrations = res.data.integrations
            Hthis.applications_proccess = res.data.applications_proccess
            Hthis.applications_work = res.data.applications_work
            Hthis.applications_cancelled = res.data.applications_cancelled

            Hthis.in_process = res.data.in_process
            Hthis.tz_conf = res.data.tz_conf
            Hthis.w8paydata = res.data.w8paydata
            Hthis.payment_w8 = res.data.payment_w8
            Hthis.payment_completed = res.data.payment_completed
            Hthis.product_ordered = res.data.product_ordered
            Hthis.product_received = res.data.product_received
            Hthis.post_posted = res.data.post_posted
            Hthis.bloger_done = res.data.bloger_done
            Hthis.w8_bloger_feedback = res.data.w8_bloger_feedback

            Hthis.applications_done = res.data.applications_done
        }).catch(error => {
            console.log(error)
        })
    }

    async LoadStat(token: string){
        let data = {
            "error": false,
            "msg": {
                name: "",
                tglogin: "",
                phone: "",
                minidesc: "",
                //image: "",
                active: true,
                bDate: "",
                desc: "",
                doc: "",
                docConf: false,
                infoConf: false,
                phoneConf: false,
                goodreview: "",
                badreview: "",
                subscribeDate: "",
                integrationsCount: ""
            }
        }
        const Hthis = this

        await axios.get(`${link}seller-update/`,{
            params: {
                'pk': Hthis.pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            data = {
                "error": false,
                "msg": {
                    name: res.data.msg.name,
                    tglogin: res.data.msg.tglogin,
                    phone: res.data.msg.phone,
                    minidesc: res.data.msg.minidesc,
                    //"image": "/media/9dc3fbfcb3424576bd651a1b94731915.png",
                    active: res.data.msg.active,
                    bDate: res.data.msg.bDate != null ? res.data.msg.bDate : "",
                    desc: res.data.msg.desc != null ? res.data.msg.desc : "",
                    doc: res.data.msg.doc != null ? res.data.msg.doc : "",
                    docConf: res.data.msg.docConf,
                    infoConf: res.data.msg.infoConf,
                    phoneConf: res.data.msg.phoneConf,
                    goodreview: res.data.msg.goodreview,
                    badreview: res.data.msg.badreview,
                    subscribeDate: res.data.msg.subscribeDate,
                    integrationsCount: res.data.msg.integrationsCount
                }
            }
        }).catch(error => {
            console.log(error)
        })

        return data
    }

    async DelItem(array: number[], token: string){
        await axios.post(`${link}seller-del/`, {
            pk: [this.pk]
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            console.log(response.data)
            console.log("true")
        })
    }
}
export class Sellers {
    list: SellerItem[] = []
    countPage: number = 1
    targetPage: number = 1

    constructor(){}

    async LoadData(token: string, search: string, page: number, isOwn: boolean){
        if(page != 0)
            this.targetPage = page

        const Hthis = this

        await axios.get(`${link}seller/`, {
            params: {
                "search": search,
                "page": page > 0 ? Hthis.targetPage:"",
                isOwn
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(res){
            Hthis.list = []
            res.data.content.forEach((item: SellerItem, index: number) => {
                Hthis.list.push(new SellerItem({...item}))
            })
            Hthis.countPage = Math.ceil(res.data.pageCount / 100)

        }).catch(function(error){
            console.log(error)
        })

    }

    async LoadExcel(token: string, search: string, selected: string){
        let array: any[] = []
        await axios.get(`${link}seller-excel/`, {
            params: {
                search: search,
                selected: selected
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            array = res.data.msg
        }).catch(error => {
            console.log(error)
        })

        return array
    }

    async DelItem(array: number[], token: string){
        await axios.post(`${link}seller-del/`, {
            pk: array
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(function(response){
            console.log(response.data)
            console.log("true")
        })
    }

}
