import { useContext } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "../context/GlobalContext"

export const BlogerListItem = (props: any) => {
    const auth = useContext(GlobalContext)   
    const checkTest = () => {
        let flag = false
        props.selectArray.forEach((item: number)=>{
            if(item == props.item.pk)
                flag = true
        })
        return flag
    }
    
    return(
        <>
            <tr>
                <td>
                    <div>
                        <input 
                            type="checkbox" 
                            checked={checkTest()}
                            onClick={()=>{
                                let flag = false
                                const hArray = props.selectArray.map((item: number)=>{
                                    if(item == props.item.pk)
                                        flag = true
                                    return item 
                                })
                                if(flag){
                                    hArray.splice(hArray.indexOf(props.item.pk), 1);
                                }else{
                                    hArray.push(props.item.pk)
                                }
                                console.log(props.selectArray)
                                props.setSelectArray(hArray)
                            }}
                        />
                    </div>
                </td>
                <td>
                    <div>
                        <div>
                            <Link to={`/bloger/${props.item.pk}/`}>{props.item.pk}</Link>
                        </div>
                    </div>
                </td>
                <td>
                    <div>
                        <Link to={`/bloger/${props.item.pk}/`}>{props.item.user.email}</Link>
                    </div>
                </td>
                <td>
                    <div>
                        {props.item.name}
                    </div>
                </td>
                <td>
                    <div>
                        {props.item.phone}
                    </div>
                </td>
                <td>
                    <div>   
                        {
                            props.item.youtube != null ? (
                                <>
                                    <Link to={`${props.item.youtube}`} target="_blank">{props.item.youtube}</Link><br/>
                                </>
                            ):""
                        }
                        {
                            props.item.vk != null ? (
                                <>
                                    <Link to={`${props.item.vk}`} target="_blank">{props.item.vk}</Link><br/>
                                </>
                            ):""
                        }
                        {
                            props.item.instagram != null ? (
                                <>
                                    <Link to={`${props.item.instagram}`} target="_blank">{props.item.instagram}</Link><br/>
                                </>
                            ):""
                        }
                        {
                            props.item.rutube != null ? (
                                <>
                                    <Link to={`${props.item.rutube}`} target="_blank">{props.item.rutube}</Link><br/>
                                </>
                            ):""
                        }
                        {
                            props.item.telegram != null ? (
                                <>
                                    <Link to={`${props.item.telegram}`} target="_blank">{props.item.telegram}</Link><br/>
                                </>
                            ):""
                        }
                    </div>
                </td>
                <td>
                    <div>
                        {props.item.tglogin}
                    </div>
                </td>
                <td>
                    <div>
                        {props.item.subscribers}
                    </div>
                </td>
                <td>
                    {props.item.date}
                </td>
                <td>
                    <div>  
                        <div aria-expanded="false" data-bs-toggle="dropdown" className="dropmenu__wrapper">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <div className="dropmenu">
                                <ul>
                                    <li
                                        onClick={()=>{
                                            props.setTargetItem(props.item.pk)
                                            props.setShowFormUpdate(true)
                                            props.setLoadForm2(true)
                                            props.item.LoadStat(auth.user.userToken).then(function(response: any){
                                                console.log({...response.msg})
                                                props.setFormUpdate({...response.msg})
                                                props.setLoadForm2(false)
                                            }).catch(function(error: any){
                                                alert("error")
                                            })
                                        }}
                                    >
                                        Изменить
                                    </li>
                                    {
                                        auth.user.userRole == "director" ? (
                                            <li
                                                onClick={()=>{
                                                    props.setSelectArray([props.item.pk])
                                                    props.setDelIntegrationsConf(true)
                                                }}
                                            >
                                                Удалить
                                            </li>
                                        ):""
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}