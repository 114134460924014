import { ChangeEvent, useContext, useEffect, useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalContext"
import { Blogers } from "../../classes"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"
import { BlogerListItem } from "../../component/BlogerListItem"
import xlsx from "json-as-xlsx"

export const BlogerListPage = () => {
    const auth = useContext(GlobalContext)  
    const [blogers, setBlogers] = useState(new Blogers())
    const [load, setLoad] = useState(true)
    const [targetItem, setTargetItem] = useState(0)
    const [loadForm, setLoadForm] = useState(true)
    const [loadForm2, setLoadForm2] = useState(true)
    const [search, setSearch] = useState("")
    const [showFormUpdate, setShowFormUpdate] = useState(false)
    
    const [legal, setLegal] = useState(false)

    const LoadData = (page = 1) => {
        setLoad(true)
        blogers.LoadData(auth.user.userToken, search, page, legal).then(function(response){
            setLoad(false)
        })
    }

    const [form, setForm] = useState({
        active: false,
        tglogin: "",
        name: "",
        phone: "",
        image: "",
        bDate: "",
        city: "",
        minidesc: "",
        category: "",
        regLink: "",
        youtube: "",
        vk: "",
        instagram: "",
        rutube: "",
        telegram: "",
        desc: "",
        doc: "",
        subscribers: "",
        men: "",
        woman: "",
        docConf: false,
        infoConf: false,
        phoneConf: false,
        CatalogConf: false,
        integrationCost: ""
    })
    const changeHandlerUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    function handlerImage(e: any){
        setForm({ ...form, [e.target.name]: e.target.files[0] })
        console.log(e.target.files)
    }

    const [selectArray, setSelectArray] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState(false)
    const [delIntegrationsConf, setDelIntegrationsConf] = useState(false)


    const DelIntegrations = (array: number[]) => {
        blogers.DelItem(array, auth.user.userToken).then(function(response){
            alert(`${array.length} блогеров успешно удалено`)
            window.location.reload()
        }).catch(function(error){
            alert("error")
        })
    }


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(loadForm){
            setLoadForm(false)

            console.log(form)
            const formData = new FormData() 

            formData.append("active", `${form.active}`)
            formData.append("tglogin", form.tglogin)
            formData.append("name", form.name)
            formData.append("phone", form.phone)
            formData.append("image", form.image)
            formData.append("bDate", form.bDate)
            formData.append("city", form.city)
            formData.append("minidesc", form.minidesc)
            formData.append("category", form.category)
            formData.append("regLink", form.regLink)
            formData.append("youtube", form.youtube)
            formData.append("vk", form.vk)
            formData.append("instagram", form.instagram)
            formData.append("rutube", form.rutube)
            formData.append("telegram", form.telegram)
            formData.append("desc", form.desc)
            formData.append("doc", form.doc)
            formData.append("subscribers", form.subscribers)
            formData.append("men", form.men)
            formData.append("woman", form.woman)
            formData.append("docConf", `${form.docConf}`)
            formData.append("infoConf", `${form.infoConf}`)
            formData.append("phoneConf", `${form.phoneConf}`)
            formData.append("CatalogConf", `${form.CatalogConf}`)
            formData.append("integrationCost", form.integrationCost)

            await axios.post(`https://api.blogerito.ru/dashboard/bloger-update/`, formData, {
                params: {
                    'id': targetItem
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Token '+auth.user.userToken
                }
            }).then(res => {  
                setLoadForm(true)
                console.log(res.data)
                alert(res.data.msg) 
            }).catch(error => {
                setLoadForm(true)
                alert("error")
            })
        }
    }


    const LoadExcel = () => {
        blogers.LoadExcel(
            auth.user.userToken,
            search, 
            selectArray.toString(),
            legal
        ).then(function(response){
            const data = [
                {
                    sheet: "Adults",
                    columns: [
                        { label: "id", value: "id" },
                        { label: "Email", value: "email" },
                        { label: "Имя", value: "name" },
                        { label: "Ник в Телеграм", value: "tglogin" },
                        { label: "Ссылка на соц.сеть", value: "regLink" },
                        { label: "Кол-во подписчиков", value: "subscribers" },
                        { label: "Дата рождения", value: "bDate" },
                        { label: "Дата регистрации", value: "date" },
                        { label: "Рейтинг", value: "integration" },
                        { label: "Отзыв+", value: "goodreview" },
                        { label: "Отзыв-", value: "badreview" },
                        { label: "Кол-во принятых интеграций", value: "applications_proccess" },
                        { label: "Кол-во интеграций в работе", value: "applications_work" },
                        { label: "Кол-во интеграций завершено", value: "applications_done" },
                        { label: "Кол-во интеграций отменено", value: "applications_cancelled" },
                        { label: "Аккаунт подтвержден", value: "infoConf" },
                        { label: "Документы подтверждены", value: "docConf" },
                        { label: "Статус заявок (Заявка принята)", value: "in_process"},
                        { label: "Статус заявок (ТЗ принято)", value: "in_process"},
                        { label: "Статус заявок (Ожидание реквизитов)", value: "w8-paydata"},
                        { label: "Статус заявок (Ожидание оплаты)", value: "payment_w8"},
                        { label: "Статус заявок (Блогер подтвердил оплату)", value: "payment_completed"},
                        { label: "Статус заявок (Товар заказан)", value: "product_ordered"},
                        { label: "Статус заявок (Товар получен)", value: "product_received"},
                        { label: "Статус заявок (Пост размещен)", value: "post_posted"},
                        { label: "Статус заявок (Блогер подтвердил выполнение)", value: "bloger_done"},
                        { label: "Статус заявок (Ожидание отзыва от блогера)", value: "w8_bloger_feedback"},
                        { label: "Процент отмены интеграций", value: "cancelled_proc"},
                        { label: "Процент завершенных интеграций", value: "done_proc"},
                        { label: "Дата последнего входа на сайт", value: "lastlogin"},

                        { label: "Юр.лицо ФИО", value: "LegalFIO" },
                        { label: "Юр.лицо ИНН", value: "LegalINN" },
                        { label: "Консультация (Чекбок)", value: "LagalConsultation" },
                    ],
                    content: response
                }, 
            ]
            console.log(data)
            let settings = {
            fileName: "Блогеры"
            }
            xlsx(data, settings)
        })
    }


    useEffect(()=>{
        LoadData()
    }, [])

    if(load){
        return(
            <Loader />
        )
    }


    return(
        <>
            <div className="content">
                <div className="row">
                    <div className="col-md-4">
                        <div className="block-wrapper">
                            <form className="sform" onSubmit={(e)=>{
                                e.preventDefault()
                                LoadData(blogers.targetPage)
                            }}>
                                <div style={{
                                    marginBottom: 10
                                }}>
                                    <input type="text" placeholder="Поиск" value={search} onChange={(e)=>{
                                        setSearch(e.target.value)
                                    }} />
                                    <button>
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                                <label style={{
                                    fontSize: 14
                                }}>
                                    <input 
                                        type="checkbox"
                                        style={{
                                            top: 2,
                                            position: "relative",
                                            width: 20
                                        }}

                                        checked={legal} 
                                        onChange={()=>{
                                            setLegal(!legal)
                                        }}
                                    />
                                    Только Юр.лица
                                </label>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="right"> 
                            {
                                selectArray.length ? (
                                    auth.user.userRole == "director" ? (
                                        <button 
                                            className="btn" 
                                            style={{background: "#ff4646"}}
                                            onClick={()=>{
                                                setDelIntegrationsConf(true)
                                            }}
                                        >
                                            Удалить
                                        </button> 
                                    ):""
                                ):""
                            }
                            {
                                auth.user.userRole == "director" ? (
                                    <button 
                                        className="btn" 
                                        style={{background: "#207444"}}
                                        onClick={()=>{
                                            LoadExcel()
                                        }}
                                    >
                                        Выгрузить в excel
                                    </button>
                                ):""
                            }
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="block-wrapper table-wrapper">
                            <div
                                style={{
                                    fontSize: 12
                                }}
                            >
                                Выбрано: {selectArray.length}
                            </div>
                            <table className="list-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                <input
                                                    type="checkbox"  
                                                    checked={selectAll}
                                                    onClick={()=>{
                                                        const Harray: number[] = []
                                                        if(!selectAll){
                                                            blogers.list.forEach((item) => {
                                                                Harray.push(item.pk)
                                                            })
                                                        }
                                                        setSelectArray(Harray)
                                                        setSelectAll(!selectAll)
                                                    }} 
                                                 />
                                            </div>
                                        </th>
                                        <th>
                                            id
                                        </th>
                                        <th>
                                            Пользователь
                                        </th>
                                        <th>
                                            ФИО
                                        </th>
                                        <th>
                                            Номер телефона
                                        </th>
                                        <th style={{width: "100px"}}>
                                            Cоц сети
                                        </th>
                                        <th>
                                            ТГ блогера
                                        </th>
                                        <th>
                                            Количество подписчиков
                                        </th>
                                        <th>
                                            Дата регистрации
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        blogers.list.length ? (
                                            blogers.list.map((item, index) => {
                                                return(
                                                    <BlogerListItem 
                                                        item={item}
                                                        setTargetItem={setTargetItem}
                                                        setShowFormUpdate={setShowFormUpdate}
                                                        setLoadForm2={setLoadForm2}
                                                        setFormUpdate={setForm}
                                                        setSelectArray={setSelectArray}
                                                        selectArray={selectArray}
                                                        setDelIntegrationsConf={setDelIntegrationsConf}
                                                    />
                                                )
                                            })
                                        ):""
                                    } 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Pagination
                    page={blogers.targetPage}
                    pageCount={blogers.countPage}
                    loadData={LoadData}
                />
            </div>
        
            {
                showFormUpdate ? (
                    <div className="popup-integration">
                        <div className="">
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setShowFormUpdate(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
                            {
                                loadForm2 ? (
                                    <div style={{backgroundColor: "white", borderRadius: 10, paddingTop: 20}}>
                                        <Loader />
                                    </div>
                                ):(
                                    <form onSubmit={handleSubmit}>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.active} 
                                                onChange={()=>{setForm({...form, active: !form.active})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Блогер активный
                                        </label>
                                        <label> 
                                            Логин в telegram
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="tglogin" 
                                                value={form.tglogin} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            ФИО блогера
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="name" 
                                                value={form.name} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Номер телефона
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="phone" 
                                                value={form.phone} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label>
                                            Фото
                                            <input 
                                                type="file"
                                                name="image"
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                accept="image/*"
                                                onChange={handlerImage}
                                            />
                                        </label>
                                        <label> 
                                            Дата рождения
                                            <input 
                                                type="date" 
                                                maxLength={255} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="bDate" 
                                                value={form.bDate} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Город
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="city" 
                                                value={form.city} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Краткое описание
                                            <input 
                                                type="text" 
                                                maxLength={80} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="minidesc" 
                                                value={form.minidesc} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Категория
                                            <input 
                                                type="text" 
                                                maxLength={255} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="category" 
                                                value={form.category} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Ссылка на соцсеть*
                                            <input 
                                                type="text" 
                                                maxLength={510}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="regLink" 
                                                value={form.regLink} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            youtube
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="youtube" 
                                                value={form.youtube} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            vk
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="vk" 
                                                value={form.vk} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            instagram
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="instagram" 
                                                value={form.instagram} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            rutube
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="rutube" 
                                                value={form.rutube} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            telegram
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="telegram" 
                                                value={form.telegram} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label> 
                                        <label>
                                            Полный текст о вас*
                                            <textarea name="desc" value={form.desc} onChange={(e)=>{
                                                setForm({ ...form, "desc": e.target.value })
                                            }} />
                                        </label>
                                        <label>
                                            Документ
                                            <input 
                                                type="file"
                                                name="doc"
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                accept="image/*"
                                                onChange={handlerImage}
                                            />
                                        </label>
                                        <label> 
                                            Кол-во подписчиков
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="subscribers" 
                                                value={form.subscribers} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Мужчины %
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="men" 
                                                value={form.men} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Мужчины %
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="woman" 
                                                value={form.woman} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.docConf} 
                                                onChange={()=>{setForm({...form, docConf: !form.docConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Документы подтверждены
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.infoConf} 
                                                onChange={()=>{setForm({...form, infoConf: !form.infoConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Аккаунт подтвержден
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.phoneConf} 
                                                onChange={()=>{setForm({...form, phoneConf: !form.phoneConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Телефон подтверждены
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.CatalogConf} 
                                                onChange={()=>{setForm({...form, CatalogConf: !form.CatalogConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Аккаунт подтвержден (Чекбок в каталоге)
                                        </label>
                                        <label> 
                                            Цена интеграции
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="integrationCost" 
                                                value={form.integrationCost} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <button>Обновить блогера</button> 
                                    </form>
                                )
                            }
                        </div>
                    </div>
                ):""
            }

            {
                delIntegrationsConf ? (
                    <div className="popup-error">
                        <div>
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setDelIntegrationsConf(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
                            <h3>Вы уверены, что хотите удалить блогеров? </h3>
                            <div className="btn-wrapper">
                                <button
                                    onClick={()=>{
                                        DelIntegrations(selectArray)
                                    }}
                                >
                                    Да
                                </button>
                                <button
                                    onClick={()=>{
                                        setDelIntegrationsConf(false)
                                    }}
                                >
                                    Отменить
                                </button>
                            </div> 
                        </div>
                    </div>
                ):""
            }
        </>
    )
}