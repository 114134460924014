import {axiosBase} from "../constants/axiosBase";
import { saveAs } from 'file-saver';


export const exportBalanceOnExel = async (sellerId:number) => {
    const response = await axiosBase.get('/export-excel/balance',{
        params:{
            sellerId
        },
        responseType: 'blob'
    })
    saveAs(new Blob([response.data]), 'Отчет.xlsx')
}
