import React, { ChangeEvent, useContext, useState, Component } from "react"
import {Route} from 'react-router-dom'  
import { BlogerListPage } from "./pages/BlogerListPage"
import { SellerListPage } from "./pages/SellerListPage"
import { IntegrationListPage } from "./pages/IntegrationListPage"
import { ReportPage } from "./pages/ReportPage"
import { ComplaintsListPage } from "./pages/ComplaintsListPage" 
//import { ComplaintsItemPage } from "./pages/ComplaintsListPage/ComplaintsItemPage"
import { PaymentsListPage } from "./pages/PaymentsListPage"
import { IntegrationItemPage } from "./pages/IntegrationListPage/IntegrationItemPage"
import { BlogerItemPage } from "./pages/BlogerListPage/BlogerItemPage"
import { SellerItemPage } from "./pages/SellerListPage/SellerItemPage"
import { GlobalContext } from "./context/GlobalContext"
import { LoginPage } from "./pages/LoginPage"


export const useMyRoutes = () => { 
    const auth = useContext(GlobalContext)
    
    return (
        <>
            {
                auth.user.userToken != "" ? (
                    <>
                        {   
                            auth.user.userRole == "director" || auth.user.userRole == "blogerManager" ? (
                                <Route path={`/`} element={<BlogerListPage/>} />
                            ):(
                                <Route path={`/`} element={<PaymentsListPage/>} />
                            )
                        }
                            <Route path={`/bloger/:id/`} element={<BlogerItemPage/>} />
        

                        {
                            auth.user.userRole == "director" || auth.user.userRole == "sellerManager" ? (
                                <Route path={`/sellers/`} element={<SellerListPage/>} />
                            ):""
                        }
                            <Route path={`/sellers/:id/`} element={<SellerItemPage/>} />
                    
                        <Route path={`/payments/`} element={<PaymentsListPage/>} />
        
                        <Route path={`/integration/`} element={<IntegrationListPage/>} />
                            <Route path={`/integration/:id/`} element={<IntegrationItemPage/>} />
                    
                        <Route path={`/report/`} element={<ReportPage/>} />
        
                        <Route path={`/complaint/`} element={<ComplaintsListPage/>} />

                    </>
                ):(
                    <Route path={`/`} element={<LoginPage/>} />
                )
            }
            
        </>
    )
}