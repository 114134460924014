import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalContext"
import { Complaints } from "../../classes"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"
import xlsx from "json-as-xlsx"

export const ComplaintsListPage = () => {
    const auth = useContext(GlobalContext)  
    const [complaints, setComplaints] = useState(new Complaints())
    const [load, setLoad] = useState(true)
    const [search, setSearch] = useState("")
    const [popupMsg, setPopupMsg] = useState("")
    
    const LoadData = (page = 1) => {
        setLoad(true)
        complaints.LoadData(auth.user.userToken, search, page).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }

    const LoadExcel = () => {
        complaints.LoadData(auth.user.userToken, search).then(function(response){
            const Harray = complaints.list.map((item) => {
                return({
                    id: item.pk, 
                    reportsType: item.reportsType,
                    project: item.integration ? (`${item.integration.pk}.${item.integration.title}`) : "",
                    seller: item.integration ? (`${item.integration.seller.user.email}`) : "",
                    bloger: item.bloger ? (`${item.bloger.user.email}`) : "",
                    date: item.date,
                    msg: item.minidesc
                })
            })
            let data = [
                {
                    sheet: "Adults",
                    columns: [
                        { label: "id", value: "id" },
                        { label: "Вид жалобы", value: "reportsType" },
                        { label: "Проект", value: "project" },
                        { label: "Селлер", value: "seller" },
                        { label: "Блогер", value: "bloger" },
                        { label: "Дата", value: "date" },
                        { label: "Жалоба", value: "msg" },

                        
                    ],
                    content: Harray
                }, 
            ]
            let settings = {
            fileName: "Жалобы"
            }
            xlsx(data, settings)
        })
    }

    useEffect(()=>{
        LoadData()
    }, [])

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="row">
                    <div className="col-md-4">
                        <div className="block-wrapper">
                            <form className="sform" onSubmit={(e)=>{
                                e.preventDefault()
                                LoadData(complaints.targetPage)
                            }}>
                                <input type="text" placeholder="Поиск" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }} />
                                <button>
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="right">
                            {
                                auth.user.userRole == "director" ? (
                                    <button onClick={()=>{LoadExcel()}} className="btn" style={{background: "#207444"}}>Выгрузить в excel</button>
                                ):""
                            }
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="block-wrapper table-wrapper">
                            <table className="list-table">
                                <thead>
                                    <tr> 
                                        <th>
                                            id
                                        </th>
                                        <th>
                                            Вид жалобы
                                        </th>
                                        <th>
                                            Проект
                                        </th>
                                        <th>
                                            Селлер
                                        </th>
                                        <th>
                                            Блогер
                                        </th>
                                        <th>
                                            Дата
                                        </th>
                                        <th>
                                            Сообщение
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        complaints.list.length ? (
                                            complaints.list.map((item, index) => {
                                                return(
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    {item.pk}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{width: 100}}>
                                                                {item.reportsType}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {
                                                                    item.integration ? (
                                                                        <>
                                                                            <Link to={`/integration/${item.integration.pk}/`}>
                                                                                {item.integration.title}
                                                                            </Link>
                                                                        </>
                                                                    ):""
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {
                                                                    item.integration ? (
                                                                        <>
                                                                            <Link to={`/sellers/${item.integration.seller.pk}/`}>
                                                                                {item.integration.seller.user.email}
                                                                            </Link>
                                                                        </>
                                                                    ):""
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {
                                                                    item.bloger ? (
                                                                        <>
                                                                            <Link to={`/bloger/${item.bloger.pk}/`}>
                                                                                {item.bloger.user.email}
                                                                            </Link>
                                                                        </>
                                                                    ):""
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{width: "100px"}}>
                                                                {
                                                                    item.date
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {item.minidesc}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ):""
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Pagination
                    page={complaints.targetPage}
                    pageCount={complaints.countPage}
                    loadData={LoadData}
                />
                {
                    popupMsg != "" ? (
                        <div className="popup-msg">
                            <div>
                                <div className="close">
                                    <svg onClick={()=>{setPopupMsg("")}} width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                    </svg>
                                </div>
                                <p>{popupMsg}</p>
                            </div>
                        </div>
                    ):""
                }
            </div>

        </>
    )
    
}